export const mediaTypeModelImport = [
    ".3dm",
    ".asm",
    ".catpart",
    ".catproduct",
    ".dn",
    ".fbx",
    ".glb",
    ".gltf",
    ".iges",
    ".igs",
    ".jt",
    ".obj",
    ".prt",
    ".sbsm",
    ".skp",
    ".sldasm",
    ".sldprt",
    ".ssg",
    ".step",
    ".stl",
    ".stp",
    ".usd",
    ".usda",
    ".usdc",
    ".usdz",
    ".x_t",
] as const;
export const mediaTypeScene = [".dn", ".ssg"] as const;
export const mediaTypeImageImport = [
    ".ai",
    ".bmp",
    ".eps",
    ".epsf",
    ".exr",
    ".gif",
    ".hdr",
    ".iff",
    ".j2k",
    ".jp2",
    ".jpe",
    ".jpeg",
    ".jpf",
    ".jpg",
    ".jpm",
    ".jpx",
    ".pcx",
    ".pdf",
    ".png",
    ".psb",
    ".psd",
    ".svg",
    ".svgz",
    ".tga",
    ".tif",
    ".tiff",
] as const;
export const mediaTypeModelUsd = [
    ".3dm",
    ".3ds",
    ".3dxml",
    ".3mf",
    ".abc",
    ".arc",
    ".asm",
    ".axf",
    ".catdrawing",
    ".catpart",
    ".catproduct",
    ".catshape",
    ".cgr",
    ".dae",
    ".dlv",
    ".dwf",
    ".dwfx",
    ".dxf",
    ".exp",
    ".fbx",
    ".glb",
    ".gltf",
    ".iam",
    ".ifc",
    ".ifczip",
    ".iges",
    ".igs",
    ".ipt",
    ".jt",
    ".mf1",
    ".model",
    ".mtlx",
    ".neu",
    ".obj",
    ".par",
    ".pdf",
    ".pkg",
    ".ply",
    ".prc",
    ".prt",
    ".psm",
    ".pwd",
    ".rfa",
    ".sab",
    ".sat",
    ".sbsar",
    ".sdf",
    ".session",
    ".sldasm",
    ".sldprt",
    ".step",
    ".stl",
    ".stp",
    ".stpx",
    ".stpxz",
    ".stpz",
    ".u3d",
    ".unv",
    ".usd",
    ".usda",
    ".usdc",
    ".usdz",
    ".vda",
    ".vrml",
    ".wrl",
    ".x_b",
    ".x_t",
    ".xas",
    ".xmt",
    ".xmt_txt",
    ".xpr",
] as const;
export const mediaTypeMaterialImport = [".sbsar", ".mdlz"] as const;
export const mediaTypeJson = [".json"] as const;
export const mediaTypeText = [".txt"] as const;
export const mediaTypeModelCompact = [".fbx", ".glb", ".usdz"] as const;
export const mediaTypeImageRender = [".png", ".psd"] as const;
export const mediaTypeModelUsdArchive = [".usdz"] as const;
export const mediaTypeModelExport = [".glb", ".gltf", ".obj", ".ssg"] as const;
export const mediaTypeSceneWrite = [".ssg"] as const;
