/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { components } from "./openapi.js";
export * from "./detail.js";
export * as Extensions from "./extension-sets.js";
export * from "./health-check-types.js";
export {
    components as OpenApiComponents,
    paths as OpenApiPaths,
} from "./openapi.js";
export * from "./projects.js";
export * from "./snac.js";
export * as Stager from "./stager.js";
export * from "./studio.js";
export * from "./util.js";
export * from "./libraryAssets.js";

// https://stackoverflow.com/a/50769802
type Mutable<T> = { -readonly [P in keyof T]: T[P] };
export function cloned<T>(obj: T): Mutable<T> {
    return obj;
}

export type AssetPath = string;
export type openapi = components["schemas"];

type TextureSizes = 512 | 1024 | 2048 | 4096 | 8192 | 16384;

export type TextureProps = `texture${TextureSizes}`;

export type DnSceneProps = "meshes" | "polygons" | "materials" | "lights";

export interface DnSummary
    extends Record<DnSceneProps, number>,
        Record<TextureProps, number> {}

export type QueueId = string;
export type Environment = openapi["environment"];
export type AssetDescription = openapi["assetDescription"];
export type TestDescription = openapi["testDescription"];
export type EngineVersion = openapi["engineVersion"];

export type TemplateObject = openapi["templateObject"]
export type TestResult = openapi["testResult"];
export type VisualResult = openapi["visualResult"];

// Settings passed through that indicate to the web viewer the render settings of the template. If this is being
// updated with new requried fields, ensure that Studio's index.tsx validates loaded config files by checking for any
// and all required properties -jakes
export type RenderSettings = {
    backgroundColor?: number[]; // Should always have 4 values
    groundPlaneEnabled?: boolean;
    domeLightRotation?: number;
    domeLightIntensity?: number;
    directionalLightVector?: number[];
    shadowIntensity?: number;
    useIblShadows?: boolean;
};

// IMS Client Info
// TA = Technical Account
export interface Type1OrTA {
    userId: string;
    clientId: string;
}

export interface Type2Type3 {
    userId: string;
    orgId: string;
    clientId: string;
}

export type ClientInfo = Type1OrTA | Type2Type3

export interface K8JobLabelType {
    key: string;
    value: string;
}

//////////////
// Template //
//////////////
export type TemplateView = {
    camera: {
        customData: {
            adobe: {
                displayName: string;
            };
        };
        focalLength: number;
        horizontalAperture: number;
        verticalAperture: number;
    };
    render_product: {
        resolution: number[];
    };
    light_set: {
        DomeLight: {
            "inputs:intensity": number;
            "xformOp:rotateY": number;
        };
    };
};

export type CameraAngle = { [key: symbol]: TemplateView };

export type TemplateEntry = {
    metadata: {
        customLayerData: {
            adobe: {
                templateDescription: string;
                templateName: string;
                templateSchemaVersion: string;
                templateTags: string[];
                templateVersion: number;
            };
        };
        defaultPrim: string;
        doc: string;
        metersPerUnit: number;
        renderSettingsPrimPath: string;
        upAxis: string;
    };
    variants: CameraAngle[];
    RenderSettings: {
        "adobe:backgroundColor": number[];
        "adobe:compositingEnabled": boolean;
        "adobe:groundplane:enabled": boolean;
        "adobe:groundplane:reflectionsEnabled": boolean;
        "adobe:groundplane:reflectionsOpacity": number;
        "adobe:groundplane:reflectionsRoughness": number;
        "adobe:groundplane:shadowsEnabled": boolean;
        "adobe:groundplane:shadowsOpacity": number;
    };
    iblFilename: string;
    url: string;
};

export type LightDirVec = [x: number, y: number, z: number];

export type IBLToLightDir = {
    [key: string]: { direction: LightDirVec; shadowIntensity: number };
};

//////////
// Jobs //
//////////

// Use when submitting service job for Splunk logging.
export type ServiceJob = {
    jobId: string;
    type: string;
    compositeId?: string;
    component?: object;
};
// processId could represent a serviceJobId or a uploadId depending on the errors.
export type JobError = { processId: string | null; messages: string[] };
export type JobErrors = JobError[];

export type JobId = openapi["jobId"];
export type Job = openapi["job"];
export type JobCreate = Pick<Job, "type" | "spec">
export type JobUpdate = openapi["jobUpdate"];
export type JobPatch = openapi["jobPatch"];
export type JobTierPatch = openapi["jobTierPatch"];

// we don't pick up this type from Job to eliminate undefined
export type JobMessage = openapi["jobMessage"];

export type JobTypes = Job["type"];
export type JobContextMetadata = Job["contextMetadata"];
export type JobMetadata = Job["metadata"];
export type JobStatus = Job["status"];
export type JobStatusState = JobStatus["state"];

// Adding the following generic types as mapping over a union seems to
// lose polymorphism information sometimes
export type GraphSpec = openapi["graph"]["spec"];
export type MultiSpec = GraphSpec;
export type UsdSpec = GraphSpec;
export type Spec = GraphSpec;
export type JobRequest<T extends Spec> = {
    type: JobTypes;
    contextMetadata?: JobContextMetadata;
    spec: T;
};
export type MultiJobRequest = JobRequest<MultiSpec>;
export type JobResult = openapi["graph"]["result"];
export type MultiResponse = openapi["graph"];
export type UsdResponse = openapi["graph"];
export type SubmitResponse = { jobId: JobId };

export type StagerQueryObject = openapi["sceneMetadata"];

export type ImageResizeParameters = NonNullable<
    openapi["image.resize"]["parameters"]
>;
export type SceneRenderParameters = NonNullable<
    openapi["scene.render"]["parameters"]
>;
export type RenderQualityOption = SceneRenderParameters["quality"];
export type ModelRenderParameters = NonNullable<
    openapi["model.render"]["parameters"]
>;
export type ModelConvertParameters = NonNullable<
    openapi["model.convert"]["parameters"]
>;
export type UsdVariantParameter = NonNullable<openapi["usdVariant"]>;
export type SunriseTemplateMergeParameters = NonNullable<
    openapi["sunrise.template.merge"]["parameters"]
>;
export type SunriseTemplateMergeOverrides = NonNullable<
    SunriseTemplateMergeParameters["overrides"]
>;

export interface JobAssignment {
    jobId: JobId;
    jobIdHash: string;
}

function isString(str: unknown): boolean {
    return typeof str === "string" || str instanceof String;
}

function fieldIsInJobAssignment(
    jobAssignment: any,
    field: keyof JobAssignment,
) {
    return field in jobAssignment && isString(jobAssignment[field]);
}

export function isJobAssignment(
    jobAssignment: unknown,
): jobAssignment is JobAssignment {
    return (
        fieldIsInJobAssignment(jobAssignment, "jobId") &&
        fieldIsInJobAssignment(jobAssignment, "jobIdHash")
    );
}

// TODO: Is there an easy way to keep jobTiers in sync with openapi["tier"]
//       Note, the reason for having jobTiers at all, is to have an enumerable list of tiers for
//       the type guard below
// https://stackoverflow.com/a/69676795
const jobTiers = ["warm", "warm-gpu", "warm-stager", "cool"] as const;
export type JobTiers = openapi["tier"];
export type BaseJobTiers = openapi["baseTier"];

export function isJobTier(tier: unknown): tier is JobTiers {
    const jobTierNames = jobTiers as readonly string[];
    return (
        tier !== undefined &&
        typeof tier === "string" &&
        jobTierNames.includes(tier)
    );
}

// https://stackoverflow.com/questions/2063213/regular-expression-for-validating-dns-label-host-name
export type ClusterJobId = string;
const rfc1123 = /^(?!-)[a-zA-Z0-9-]{1,63}$/;
export function isClusterJobId(x: unknown): x is ClusterJobId {
    // https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#dns-label-names
    return x !== undefined && typeof x === "string" && rfc1123.test(x);
}

///////////
// Nodes //
///////////
export type NodeId = openapi["nodeId"];
export type GraphNode = openapi["graph.node"];
export type GraphNodes = { [k: string]: GraphNode };
export type NodeInputs = { [k: string]: NodeId };
export type NodeStatus = openapi["nodeStatus"] & {
    nodeId: NodeId;
};
export type NodeState = NodeStatus["state"];
export type NodeStats = Pick<
    NodeStatus,
    "allocCpu" | "usedCpu" | "usedMem" | "ext" | "size" | "resultVersion" | "sha"
>;

// Launchpad type
export type NodeLaunchpad = openapi["common_launchpad.generic"];
export function isLaunchpad(node: GraphNode): node is NodeLaunchpad {
    return /^launchpad\.*/.test(node.type)
}
export function isLaunchpadType(type: string): type is NodeLaunchpad["type"] {
    return /^launchpad\.*/.test(type)
}

export function getInputs(node: GraphNode): NodeInputs {
    return "inputs" in node ? node.inputs : ({} as NodeInputs);
}

export type ImageMediaExtensions = openapi["mediaTypeImageImportEnum"];
export type MaterialMediaExtensions = openapi["mediaTypeMaterialImportEnum"];
export type ModelMediaExtensions = openapi["mediaTypeModelImportEnum"];
export type ModelMediaExtensionsUsd = openapi["mediaTypeModelUsdEnum"];
export type SceneMediaExtensions = openapi["mediaTypeSceneEnum"];

export type SceneMaterial = openapi["sceneMaterial"];
export type SceneDecal = openapi["decalOrMaterial"];
export type SceneModel = openapi["listItem"];

///////////
// Files //
///////////
export type FileType = openapi["fileType"];
export type AssetURI = openapi["uri"];

///////////
// Image //
///////////
export type ResizeOptions = NonNullable<openapi["image.resize"]["parameters"]>;

//////////
// MISC //
//////////
export const SORT_ORDER = ["asc", "desc"] as const;
export type SortOrder = (typeof SORT_ORDER)[number];
