/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import { ShadowGenerator } from "@babylonjs/core/Lights/Shadows/shadowGenerator";
import { PBRMetallicRoughnessMaterial } from "@babylonjs/core/Materials/PBR/pbrMetallicRoughnessMaterial";
import { Quaternion } from "@babylonjs/core/Maths/math";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { CreateDisc } from "@babylonjs/core/Meshes/Builders/discBuilder";
import {
    SceneOptimizerOptions,
    CustomOptimization,
} from "@babylonjs/core/Misc/sceneOptimizer";
import "@babylonjs/core/Shaders/fxaa.fragment";
import "@babylonjs/core/Shaders/fxaa.vertex";
import { FxaaPostProcess } from "@babylonjs/core/PostProcesses/fxaaPostProcess";
import "@babylonjs/core/Shaders/shadowMap.fragment";
import "@babylonjs/core/Shaders/shadowMap.vertex";
import { ShadowOnlyMaterial } from "@babylonjs/materials/shadowOnly";
import { DEFAULT_ASPECT_RATIO, DEFAULT_RENDER_SETTINGS } from "@shared/common";

import { throttle } from "../utils/throttle";

import type { OptimizerSettings } from "../Studio";
import type { AdobeViewer, SerializedVector3 } from "@3di/adobe-3d-viewer";
import type { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import type { Camera } from "@babylonjs/core/Cameras/camera";
import type { SceneOptimizer } from "@babylonjs/core/Misc/sceneOptimizer";
import type { Scene } from "@babylonjs/core/scene";
import type { Nullable } from "@babylonjs/core/types";
import type { CameraOverride, RenderSettings } from "@shared/types";

export interface SceneManagerOptions {
    cameraOverride?: CameraOverride;
    onUpdateCameraPosition?: (override: CameraOverride) => void;
    enableLimitedZoom?: boolean;
    cameraName?: string;
    renderSettings?: RenderSettings;
    aspectRatio?: number;
    wideFraming?: boolean;
    optimizerSettings?: OptimizerSettings;
}

class ContactHardeningShadowOptimization extends CustomOptimization {
    private shadowGenerator?: ShadowGenerator;

    constructor(priority: number, shadowGenerator?: ShadowGenerator) {
        super(priority);
        this.shadowGenerator = shadowGenerator;
    }

    onApply = (scene: Scene, optimizer: SceneOptimizer) => {
        if (this.shadowGenerator) {
            // Turn dynamic shadow hardening off, unless optimizer is in improvement mode
            this.shadowGenerator.useContactHardeningShadow =
                optimizer.isInImprovementMode;

            // Always ensure transparencyShadow is the same, otherwise transparency artifacts may appear that
            // look crosshatched in the shadow
            this.shadowGenerator.transparencyShadow =
                optimizer.isInImprovementMode;

            this.shadowGenerator.recreateShadowMap();

            return true;
        } else {
            return false;
        }
    };

    onGetDescription = () => {
        return "Disabling contact hardening shadows (enabling in improvement mode)";
    };
}

class SsaoOptimization extends CustomOptimization {
    private viewer?: AdobeViewer;

    constructor(priority: number, viewer?: AdobeViewer) {
        super(priority);
        this.viewer = viewer;
    }

    onApply = (scene: Scene, optimizer: SceneOptimizer) => {
        if (this.viewer) {
            // Turn SSAO off, unless optimizer is in improvement mode
            this.viewer.ssaoEnabled = optimizer.isInImprovementMode;
            return true;
        } else {
            return false;
        }
    };

    onGetDescription = () => {
        return "Disabling SSAO (enabling in improvement mode)";
    };
}

export class SceneManager {
    scene: AdobeViewer["sceneManager"]["scene"];
    camera?: Nullable<ArcRotateCamera>;
    optimizer?: SceneOptimizer;
    modelBounds!: { min: Vector3; max: Vector3 };
    shadowGenerator?: ShadowGenerator;

    constructor(
        public viewer: AdobeViewer,
        private options: SceneManagerOptions,
    ) {
        if (!viewer.modelInitialized) {
            throw new Error(
                "Viewer model must be initialized before creating this manager",
            );
        }

        this.viewer = viewer;

        this.scene = viewer.sceneManager.scene;

        this.modelBounds = this.scene.getWorldExtends();
        this.setupScene();

        if (!options.renderSettings?.useIblShadows) {
            // Babylon's FPS calculations seem inconsistent and vary somewhat, so the target FPS for the optimizer will be
            // lower than the actual ideal 60 FPS, but instead an acceptable 30 FPS
            const optimizerOptions = new SceneOptimizerOptions(
                options.optimizerSettings?.optimizerFps ?? 30,
                options.optimizerSettings?.optimizerFreq ?? 5000, // Recheck FPS every 5 second
            );

            // Add a delay before applying the optimizations, because the FPS is in flux the first few seconds
            optimizerOptions.addCustomOptimization(
                () => true,
                () => "Optimizer delay finished",
                0,
            );
            optimizerOptions.addOptimization(
                new ContactHardeningShadowOptimization(1, this.shadowGenerator),
            );
            optimizerOptions.addOptimization(
                new SsaoOptimization(2, this.viewer),
            );

            // This is an intense GPU optimization, that quickly both speeds up the viewer and degrades the quality. At
            // this point, we don't think it will be necessary
            // optimizerOptions.addOptimization(
            //     new HardwareScalingOptimization(4, 1.5),
            // );

            this.viewer.startOptimizer(optimizerOptions);
        }
    }

    private setupScene() {
        if (!this.scene) {
            throw new Error(`Scene doesn't exist`);
        }
        const {
            cameraOverride,
            onUpdateCameraPosition,
            enableLimitedZoom,
            renderSettings,
        } = this.options;

        if (renderSettings?.useIblShadows) {
            this.viewer.iblShadowsEnabled = true;
        }

        this.updateBackground();

        const domeLightRotationY =
            renderSettings?.domeLightRotation != undefined
                ? renderSettings?.domeLightRotation * (Math.PI / 180) - Math.PI
                : DEFAULT_RENDER_SETTINGS.domeLightRotation;

        if (this.viewer.hdrTexture) {
            this.viewer.hdrTexture.rotationY = domeLightRotationY;
        }
        if (this.viewer.iblShadowsPipeline) {
            this.viewer.iblShadowsPipeline.envRotation = domeLightRotationY;
        }

        this.viewer.onAdobeViewerRenderCompleteObservable.addOnce(() => {
            let camera: Nullable<ArcRotateCamera> = null;
            if (this.options.cameraName) {
                const foundCamera = this.viewer.cameraManager
                    .getCameraArray()
                    .find((camera) => camera.name === this.options.cameraName);
                if (foundCamera) {
                    this.scene.activeCamera =
                        foundCamera as unknown as Nullable<Camera>;
                    camera = foundCamera as unknown as ArcRotateCamera;
                }
            }
            if (!camera) {
                camera =
                    this.viewer.cameraManager.getActiveCamera() as Nullable<ArcRotateCamera>;
            }
            if (camera) {
                const sceneRadius =
                    Vector3.Distance(
                        this.modelBounds.min,
                        this.modelBounds.max,
                    ) / 2;

                if (enableLimitedZoom) {
                    camera.lowerRadiusLimit = sceneRadius * 1.5;
                    camera.upperRadiusLimit = sceneRadius * 8;
                } else {
                    camera.lowerRadiusLimit = 0;
                    camera.upperRadiusLimit = null;
                }

                if (cameraOverride) {
                    // The values in the webviewer are normalized to the scene bounding box for rendering purposes.
                    // For the overrides that are fed back into the webviewer, we must apply the modelScale scale factor
                    // to get units back into the right coordinate space
                    const translation = cameraOverride.translation.map(
                        (value) => value * this.viewer.modelScale,
                    ) as SerializedVector3;
                    const target = cameraOverride.target.map(
                        (value) => value * this.viewer.modelScale,
                    ) as SerializedVector3;
                    camera.target.set(...target);
                    camera.setPosition(new Vector3(...translation));
                }

                if (onUpdateCameraPosition) {
                    camera.onViewMatrixChangedObservable.add(
                        throttle(() => {
                            let translation: SerializedVector3 = [0, 0, 0];
                            let target: SerializedVector3 = [0, 0, 0];
                            if (camera) {
                                camera.position.toArray(translation);
                                camera.target.toArray(target);
                                // The values in the webviewer are normalized to the scene bounding box for rendering purposes.
                                // But the overrides require the original, unscaled units.  This inverse scale factor will correct
                                // for this and return the original asset units
                                translation = translation.map(
                                    (value) => value / this.viewer.modelScale,
                                ) as SerializedVector3;
                                target = target.map(
                                    (value) => value / this.viewer.modelScale,
                                ) as SerializedVector3;
                            }
                            onUpdateCameraPosition({
                                target,
                                translation,
                            });
                        }, 250),
                    );
                }
                this.camera = camera;

                if (this.camera && !renderSettings?.useIblShadows) {
                    new FxaaPostProcess("fxaa", 1, this.camera);
                }
            }
        });

        if (!renderSettings?.useIblShadows) {
            const lightDirArr: number[] =
                renderSettings?.directionalLightVector ??
                DEFAULT_RENDER_SETTINGS.directionalLightVector;
            let lightDirVec: Vector3 = new Vector3(...lightDirArr);
            if (renderSettings?.domeLightRotation != undefined) {
                lightDirVec = lightDirVec.applyRotationQuaternion(
                    Quaternion.FromEulerAngles(
                        0,
                        -renderSettings.domeLightRotation * (Math.PI / 180) +
                            Math.PI / 2,
                        0,
                    ),
                );
            }

            const light = new DirectionalLight(
                "light",
                lightDirVec,
                this.scene,
            );
            light.shadowMinZ = 0.07;
            light.shadowMaxZ = 3.5;

            light.intensity = 0;

            const shadowIntensity: number =
                renderSettings?.shadowIntensity ??
                DEFAULT_RENDER_SETTINGS.shadowIntensity;

            this.shadowGenerator = new ShadowGenerator(1024, light);
            this.shadowGenerator.forceBackFacesOnly = true;
            this.shadowGenerator.useContactHardeningShadow = true;
            this.shadowGenerator.contactHardeningLightSizeUVRatio = 0.05;
            this.shadowGenerator.enableSoftTransparentShadow = true;
            this.shadowGenerator.transparencyShadow = true;
            this.shadowGenerator.setDarkness(1.0 - shadowIntensity);
            this.scene.meshes.forEach((mesh) => {
                this.shadowGenerator?.getShadowMap()?.renderList?.push(mesh);
                mesh.receiveShadows = true;
            });
        }

        const groundPlaneEnabled =
            renderSettings?.groundPlaneEnabled ??
            DEFAULT_RENDER_SETTINGS.groundPlaneEnabled;

        if (groundPlaneEnabled) {
            const ground = CreateDisc(
                "ground",
                {
                    radius: 20,
                },
                this.scene,
            );
            ground.rotation.x = Math.PI / 2;
            if (renderSettings?.useIblShadows) {
                const shadowMaterial = new PBRMetallicRoughnessMaterial(
                    "ground_shadow_material",
                    this.scene,
                );

                shadowMaterial.alpha = 0.001;
                shadowMaterial.alphaCutOff = 0.0005;
                ground.material = shadowMaterial;
                ground.isPickable = false;
                if (this.viewer.iblShadowsPipeline) {
                    this.viewer.iblShadowsPipeline.addExcludedMesh(ground);
                }
            } else {
                ground.material = new ShadowOnlyMaterial(
                    "groundShadows",
                    this.scene,
                );
                ground.isPickable = false;
                ground.receiveShadows = true;
            }
        }
        //@ts-ignore
        window.viewer = this.viewer;
    }

    updateBackground(backgroundColor?: [number, number, number, number]) {
        const { renderSettings } = this.options;
        // Get the background color from the render settings, from the template. If there is none, use the default
        const bgColor =
            backgroundColor ||
            renderSettings?.backgroundColor ||
            DEFAULT_RENDER_SETTINGS.backgroundColor;

        this.scene.clearColor = new Color4(...bgColor);
    }

    keyDownHandler = (e: KeyboardEvent) => {
        switch (e.key) {
            // Debug keys
            /*
            case "w":
                this.wireframeMode = !this.wireframeMode;
                break;
            */

            // User keys
            case "f":
                this.frame(this.options.aspectRatio ?? DEFAULT_ASPECT_RATIO);
                break;
        }
    };

    private _wireframeMode = false;

    /**
     * Enable hot keys for web viewer. Currently, this is simply "f" to frame the subject
     */
    public attachWindowListeners() {
        window.addEventListener("keydown", this.keyDownHandler);
    }

    /**
     * Disable hot keys for web viewer. Currently, this is simply "f" to frame the subject
     */
    public detachWindowListeners() {
        window.removeEventListener("keydown", this.keyDownHandler);
    }

    get wireframeMode() {
        return this._wireframeMode;
    }

    set wireframeMode(on: boolean) {
        this._wireframeMode = on;
        this.scene?.materials.forEach((mat) => {
            mat.wireframe = on;
        });
        this.viewer.renderLoop.toggle(100);
    }

    /**
     * Frames the camera centered on the loaded model
     * @param fixedAspect If using a fixed aspect this number will be used. If not passed it will use the canvas aspect
     * @param framePaddingScaleFactor [default 1] a scaling factor to adjust the distance from the camera to the object. Numbers less than 1 are not recommended
     */
    frame(fixedAspect: number = 0, framePaddingScaleFactor = 1) {
        const camera = this.camera;
        let aspect = fixedAspect;
        if (!aspect) {
            const rect = this.viewer.containerElement.getBoundingClientRect();
            if (rect) {
                aspect = rect.width / rect.height;
            }
        }

        if (camera) {
            // Babylon default FOV is always vertical
            const fov = Math.min(camera.fov, camera.fov * aspect);
            const bounds = this.modelBounds;
            const center = Vector3.Center(bounds.max, bounds.min);
            let radius = Vector3.Distance(center, bounds.max);
            radius *= framePaddingScaleFactor;
            const offset = radius / Math.sin(fov / 2);
            camera.target = center;
            camera.radius = offset;
        }
    }

    dispose() {
        this.detachWindowListeners();
    }
}
